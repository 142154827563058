import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  useDisconnect,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store";
import { authActions } from "../store/auth";
import { AuthModel } from "../models/auth-model";
import { updateProfile } from "../networking/networkCalls";
import { setWeb3Provider } from "../utils/web3";

export default function Header() {
  const dispatch = useDispatch<AppDispatch>();
  // @ts-ignore
  const isLoggedIn: boolean = useSelector((state) => state.auth.isLoggedIn);

  const { address, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const { open } = useWeb3Modal();
  const navigate = useNavigate();
  const { disconnect } = useDisconnect();

  const handleNavigateToResetPassword = () => {
    navigate('/reset_password');
  };

  useEffect(() => {
    const updateWalletAddress = async () => {
      await updateProfile(address);
    };

    if (isConnected && address) {
      // set provider in ethers
      setWeb3Provider(walletProvider);
      // update address in db
      updateWalletAddress();
    } else {
      setWeb3Provider(undefined);
    }
  }, [isConnected, address, walletProvider, dispatch]);

  const logout = () => {
    try {
      if (isConnected && disconnect) {
        disconnect();
      }
    } catch (error) {
      console.log("ERROR :: logout :: ", error);
    }

    localStorage.clear();
    localStorage.setItem("login", "0");

    setWeb3Provider(undefined);

    // save login state and data in redux store
    dispatch(authActions.setLoginState(false));
    const authData: AuthModel = undefined;
    dispatch(authActions.setAuthData(authData));

    navigate("/", { replace: true });
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-blue">
      <div className="container-fluid">
        <Link className="navbar-brand col-lg-2 mb-5" to="#">
          <img
            src="assets/images/RUMBLEGO-logo.png"
            alt="RumbleGO"
            width="125px"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 justify-content-center mx-auto">
            <li className="nav-item">
              <Link className="nav-link " aria-current="page" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/features">
                Features
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/nft-marketplace">
                NFT
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/dapp">
                Dapp
              </Link>
            </li>
          </ul>
          <div className="d-lg-flex col-lg-3 justify-content-lg-end align-items-center justify-content-center text-center">
            {isLoggedIn &&
              (isConnected ? (
                <button className="connect-div mx-1" onClick={(e) => open()}>
                  <div>
                    <small>Disconnect Wallet</small>
                  </div>
                </button>
              ) : (
                <button className="connect-div mx-1" onClick={() => open()}>
                  <div>
                    <small>Connect Wallet</small>
                  </div>
                </button>
              ))}
            {isLoggedIn && (
              <button className="connect-div mx-1" onClick={handleNavigateToResetPassword}>
                Reset Password
              </button>
            )}

            {!isLoggedIn && (
              <button
                className="connect-div mx-1"
                onClick={() => navigate("/login")}
              >
                Login
              </button>
            )}
            {!isLoggedIn && (
              <button
                className="connect-div mx-1"
                onClick={() => navigate("/signup")}
              >
                Signup
              </button>
            )}
            {isLoggedIn && (
              <button className="connect-div mx-1" onClick={logout}>
                <i className="fas fa-power-off"></i>
              </button>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
