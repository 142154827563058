import React from "react";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <>
      <section className="about orangeBG " id="About">
        <div className="seperatorOrangeTop" />
        <div className="px-4 py-5 text-center">
          {/* <img
            className="d-block mx-auto mb-4 rounded-5 img-fluid shadow"
            src="assets/images/Loading.jpg"
            alt="RumbleGO Loading"
            width={500}
          /> */}
          <h1
            className="fw-bold lh-1 animated"
            data-animation="fadeInUp"
            data-animation-delay={150}
          >
            What is RumbleGO?!
          </h1>
          <div className="col-lg-6 mx-auto">
            <p className="lead">
              RumbleGO is the first of many exciting play2earn games coming to
              mobile phones around the globe! Brought to you by the team at{" "}
              <strong>MetaSetGO</strong>, this game is a surefire hit!
            </p>
            <p className="lead">
              With amazing NFT characters you can own, prizes you will really
              want to win, and packed with insatiable gameplay that will make
              your cheeks hurt! The enjoyment is off the charts and with 16
              players the chaos is inevitable!
            </p>
            <p className="lead">
              Make RumbleGO your next adventure and let’s{" "}
              <strong>CHANGE THE GAME!</strong>
            </p>
          </div>
        </div>
        <div className="seperatorOrangeBottom" />
      </section>
      <section className="background">
        <div id="background-wrap">
          <div className="x1">
            <div className="cloud" />
          </div>
          <div className="x2">
            <div className="cloud" />
          </div>
          <div className="x3">
            <div className="cloud" />
          </div>
          <div className="x4">
            <div className="cloud" />
          </div>
          <div className="x5">
            <div className="cloud" />
          </div>
          <div className="x1">
            <div className="cloud" />
          </div>
          <div className="x2">
            <div className="cloud" />
          </div>
        </div>
      </section>
    </>
  );
}
