import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
   <>
   <footer className="blueBG">
  <div className="seperator" />
  <div className="container text-center">
    <div className="row align-items-center">
      <div className="col-12">
        <img src="assets/images/RUMBLEGO-logo.png" width="300px" alt="RumbleGO Logo" />
      </div>
      <div className="col-sm-12 col-md-3">
        <h3>Community</h3>
        <Link to="https://t.me/MetaSetGo" target="_blank" rel="noopener noreferrer" tabIndex={0}>
          <i className="fab fa-brands fa-telegram " />
        </Link>
      </div>
      <div className="col-sm-12 col-md-3">
        <h3>Socials</h3>
        <Link to="https://twitter.com/RumbleGO_" target="_blank" rel="noopener noreferrer" tabIndex={0}>
          <i className="fab fa-twitter btn-icon-inner" />
        </Link>
      </div>
      <div className="col-sm-12 col-md-3">
        <h3>NFT</h3>
        <Link to="/nft-marketplace"><i className="fab fa-brands fa-microsoft" />
        </Link>
      </div>
      <div className="col-sm-12 col-md-3">
        <h3>Email</h3>
        <Link to="mailto:internal@rumblego.io" target="_blank" rel="noopener noreferrer"><i className="fa fab fa-solid fa-envelope fa-2xl" /></Link>
      </div>
    </div>
    <div className="row mt-4">
      <div className="col">
        <h4>Brought to you by</h4>
        <img src="assets/images/MsgoNewLogoLight2.png" className="img-fluid mb-2" width="100px" alt="MetaSetGO Logo" />
        <p><Link to='https://app.termly.io/policy-viewer/policy.html?policyUUID=94ab652c-5faf-4ec9-b5ba-07bbe7e746e5' target='_blank'>Terms of Service</Link></p>
        <p className="text-white">© 2024 RumbleGO - All Rights Reserved</p>
      </div>
    </div>
  </div>
</footer>
   </>
  )
}
