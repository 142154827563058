import React from "react";

export default function Features() {
  return (
    <>
      <section className="features" id="Features">
        <div className="seperator" />
        <div className="container px-4 py-5">
          <div className="row row-cols-1 row-cols-md-2 align-items-md-center g-5 py-5">
            <div className="col d-flex flex-column align-items-start gap-2">
              <h1 className="fw-bold animated" data-animation="fadeInUp">
                RumbleGO Features
              </h1>
              <p>
                We're making sure there's plenty of features available to keep
                everyone entertained!{" "}
              </p>
            </div>
            <div className="col">
              <div id="carouselExampleIndicators" className="carousel slide">
                <div>
                  <ol className="carousel-indicators">
                    <li
                      data-target="#carouselExampleIndicators"
                      data-bs-slide-to={0}
                      className="active"
                    />
                    <li
                      data-target="#carouselExampleIndicators"
                      data-bs-slide-to={1}
                    />
                    {/* <li
                      data-target="#carouselExampleIndicators"
                      data-bs-slide-to={2}
                    /> */}
                  </ol>
                </div>
                <div className="carousel-inner">
                  {/* <div className="carousel-item active">
                    <img
                      src="assets/images/RollingJackpot3.png"
                      className="d-block w-100"
                      alt="Rolling Jackpot Feature"
                    />
                  </div> */}
                  <div className="carousel-item active">
                    <img
                      src="assets/images/NewLevels.png"
                      className="d-block w-100"
                      alt="New Levels Added"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src="assets/images/NFTCharacters.png"
                      className="d-block w-100"
                      alt="NFT Characters"
                    />
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  >
                    <i className="fa fab fa-solid fa-arrow-left" />
                  </span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  >
                    <i className="fa fab fa-solid fa-arrow-right" />
                  </span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="seperatorBottom" />
      </section>

      <section className="background" />
    </>
  );
}
